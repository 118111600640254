<template>
  <div class="content-page">
    <div class="content-nav">
      <el-breadcrumb class="breadcrumb" separator="/">
        <el-breadcrumb-item>人才招聘</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="operation-nav">
        <router-link to="/dashboard/recuirt/add">
          <el-button type="primary" icon="plus">新增职位</el-button>
        </router-link>
      </div>
      <div class="operation-nav">
        <div style="margin-left: 10px"></div>
      </div>
    </div>
    <div class="content-main">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="发布中" name="first"></el-tab-pane>
        <el-tab-pane label="已关闭" name="second"></el-tab-pane>
      </el-tabs>

      <div class="filter-box" v-if="status != 999">
        <!-- <el-form :inline="true" :model="filterForm">
          <el-form-item>
            <el-button type="primary" @click="onSubmitFilter">刷新</el-button>
          </el-form-item>
        </el-form> -->
      </div>
      <div class="filter-box" v-if="status != 999">
        <el-form :inline="true" :model="filterForm">
          <!-- <el-form-item>
            <el-button type="primary" icon="plus" @click="addNew"
              >添加询单</el-button
            >
          </el-form-item> -->
        </el-form>
      </div>

      <div class="form-table-box" v-if="status != 999">
        <el-table :data="tableData" style="width: 100%" border stripe>
          <el-table-column prop="title" label="岗位名称" width="150">
            <template scope="scope">
              <div>{{ scope.row.title }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="类型" width="110">
            <template scope="scope">
              <el-button
                v-if="scope.row.type == '全职'"
                type="primary"
                size="small"
                >全职</el-button
              >
              <el-button
                v-if="scope.row.type == '兼职'"
                type="success"
                size="small"
                >兼职</el-button
              >
            </template>
          </el-table-column>

          <el-table-column prop="num" label="招聘人数" width="80">
            <template scope="scope">
              <div>{{ scope.row.num }}</div>
            </template>
          </el-table-column>

          <el-table-column prop="experience" label="经验要求">
            <template scope="scope">
              <div>{{ scope.row.experience }}</div>
            </template>
          </el-table-column>

          <el-table-column prop="is_show" label="薪资待遇">
            <template scope="scope">
              <div>{{ scope.row.minSalary }}-{{ scope.row.maxSalary }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="发布时间" width="180">
            <template scope="scope">
              <div>{{ scope.row.create_time }}</div>
            </template>
          </el-table-column>

          <el-table-column prop="view_num" label="看过我(人数)" width="180">
            <template scope="scope">
              <el-button type="success" size="small"
                >{{ scope.row.view_num }}人</el-button
              >
            </template>
          </el-table-column>

          <el-table-column prop="is_show" label="状态">
            <template scope="scope">
              <el-switch
                v-model="scope.row.is_show"
              
                @change="statusChange(scope.row, scope.index)"
              >
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="250">
            <template scope="scope">
              <el-button
                size="small"
                type="primary"
                @click="handleRowEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                size="small"
                type="danger"
                @click="handleRowDelete(scope.$index, scope.row)"
                >删除</el-button
              >
              <el-button round type="success" size="medium" @click="openTips"
                >上首页</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="form-table-box" v-if="status == 999">
        <div id="xundanfenbu" style="width: 100%; height: 400px"></div>
        <div id="xundanfenbu-kehu" style="width: 100%; height: 400px"></div>
        <div id="chengjiaolv" style="width: 50%; height: 400px"></div>
      </div>

      <div class="page-box" v-if="status != 999">
        <el-pagination
          background
          @current-change="handlePageChange"
          :current-page.sync="page"
          :page-size="30"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";

export default {
  name: "WaitMatter",
  data() {
    return {
      statusCateOptions: [
        {
          value: 1,
          label: "待跟进",
        },
        {
          value: 2,
          label: "已成交",
        },
        {
          value: 3,
          label: "已放弃",
        },
      ],
      phoneCateOptions: [],

      followData: [],
      formLabelWidth: "80px",
      followDialogVisible: false,
      page: 1,
      total: 0,
      activeName: "first",
      status: 1,
      followResult: {
        id: "",
        content: "",
        result: "wait",
        money: "",
      },
      filterForm: {
        customer: "",
        phone: "",
        content: "",
        remark: "",
      },
      tableData: [],
      fakeData: [],
      fake: 0,
      loginInfo: null,
      username: "",
    };
  },
  methods: {
    openTips() {
      this.$alert("请联系您的商务经理帮助您开通上首页", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.axios
            .get("dynamic/addClick", {
              params: {
                event: "recuirt",
              },
            })
            .then((response) => {});
        },
      });
    },
    // tableRowClassName({row, rowIndex}){
    //   if (rowIndex === 1) {
    //       return 'warning-row';
    //     } else if (rowIndex === 3) {
    //       return 'success-row';
    //     }
    //     return '';
    // },
    addNew() {
      var myDate = new Date();
      let map = {
        status: 1,
        content: "",
        create_time: myDate.toLocaleTimeString(),
        customer: "",
        lastFollowContent: "",
        money: "",
        phone: "",
        remark: "",
      };
      this.axios.post("opportunity/editAllField", map).then((response) => {
        if (response.data.errno === 0) {
          this.$message({
            type: "success",
            message: "新增成功",
          });
          this.tableData.unshift(response.data.data);
        } else {
          this.$message({
            type: "error",
            message: "新增失败",
          });
        }
      });
    },
    contentChange(index, row) {
      let parmas = this.tableData[index];

      this.axios.post("opportunity/editAllField", parmas).then((response) => {
        if (response.data.errno === 0) {
          this.$message({
            type: "success",
            message: "保存成功",
          });
        } else {
          this.$message({
            type: "error",
            message: "保存失败",
          });
        }
      });
    },

    statusChange(index, row) {
      let status = this.tableData[index].status;
      let id = this.tableData[index].id;

      if (status) {
        this.axios
          .post("opportunity/changeStatus", {
            id: id,
            status: status,
          })
          .then((response) => {
            if (response.data.errno === 0) {
              this.$message({
                type: "success",
                message: "修改成功",
              });
            } else {
              this.$message({
                type: "error",
                message: "修改失败",
              });
            }
          });
      }
    },

    statusChange(row, index) {
      this.axios
        .get("recuirt/changeStatus", {
          params: {
            id: row.id,
            is_show: row.is_show,
          },
        })
        .then((response) => {
          if (response.data.errno === 0) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
          } else {
            this.$message({
              type: "error",
              message: "修改失败",
            });
          }
        });
    },
    handleClick(tab, event) {
      let pindex = tab._data.index;
      if (pindex == 0) {
        this.status = 1;
      } else if (pindex == 1) {
        this.status = 2;
      } else if (pindex == 2) {
        this.status = 3;
      } else if (pindex == 3) {
        this.status = 99;
      } else if (pindex == 4) {
        this.status = 999;
        this.getCharts();
        return;
      }
      this.getList();
    },
    getCharts() {
      var myChart = echarts.init(document.getElementById("xundanfenbu"));

      // 指定图表的配置项和数据
      var option = {
        title: {
          text: "ECharts 入门示例",
        },
        tooltip: {},
        legend: {
          data: ["销量"],
        },
        xAxis: {
          data: ["衬衫", "羊毛衫", "雪纺衫", "裤子", "高跟鞋", "袜子"],
        },
        yAxis: {},
        series: [
          {
            name: "销量",
            type: "bar",
            data: [5, 20, 36, 10, 10, 20],
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    addOpppritunity() {
      this.$router.push({ name: "followadd" });
    },
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },

    submitRemark(index, row) {
      this.axios
        .post("opportunity/updateRemark", {
          id: row.id,
          remark: row.remark,
        })
        .then((response) => {
          if (response.data.errno === 0) {
            this.$message({
              type: "success",
              message: "修改备注成功!",
            });
          }
        });
    },
    submitContent(index, row) {
      this.axios
        .post("opportunity/updateContent", {
          id: row.id,
          content: row.content,
        })
        .then((response) => {
          if (response.data.errno === 0) {
            this.$message({
              type: "success",
              message: "修改询价内容成功!",
            });
          }
        });
    },
    handleRowEdit(index, row) {
      this.$router.push({ name: "recuirt_add", query: { id: row.id } });
    },

    handleRowDelete(index, row) {
      this.$confirm("确定要删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios.post("recuirt/destory", { id: row.id }).then((response) => {
          if (response.data.errno === 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          }
        });
      });
    },

    onSubmitFilter() {
      this.page = 1;
      this.getList();
    },
    resetFilter() {
      this.filterForm = {
        customer: "",
        phone: "",
        content: "",
        remark: "",
      };

      this.onSubmitFilter();
    },
    getFollowById(id) {
      this.axios.post("opportunity/followList", { id: id }).then((response) => {
        if (response.data.errno === 0) {
          this.followData = response.data.data;
        }
      });
    },
    getList() {
      this.axios
        .get("recuirt", {
          params: {
            page: this.page,
            customer: this.filterForm.customer,
            phone: this.filterForm.phone,
            content: this.filterForm.content,
            remark: this.filterForm.remark,
            status: this.status,
          },
        })
        .then((response) => {
          this.tableData = response.data.data.data;
          this.page = response.data.data.currentPage;
          this.total = response.data.data.count;
        });
    },
  },
  components: {},
  mounted() {
    this.page = 1;
    this.getList();
  },
};
</script>

<style scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.el-table /deep/ .cell {
  display: flex;
}

.form-table-box /deep/ .el-input__inner .redSelect {
  color: white;
  background: red;
  border-color: red;
}
</style>
